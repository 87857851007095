import "./Technologies.css";

const Technologies = () => {
  return (
    <div className="technologies">
      <h1>
        <b>Education</b>
      </h1>
      <h4>
        My name is Nathan Lapak and I am currently pursuing a BSc Specialization in Computing Science 
        at the University of Alberta.
      </h4>

      <br/>
      <h4>My favorite classes during my program so far have been CMPUT 229, CMPUT 301 and CMPUT 201.</h4>

      <br/>
      <br/>

      <h4>CMPUT 229: Comptuer Organization and Architecture</h4>
      <p>This class was pretty difficult, but the content was incredibly interesting and insightful. The labs were done using RISC-V assembly
      and RARS and was very time consuming to complete. <br/> The class covered topics like RISC-V programming, pipelining, cache memory, and
      memory hiearchy. Though this class has a reputation for being difficult, I think every CS <br/> student should take it so that they have a better 
      understanding of low-level programming, which makes them a better programmer in general.

      </p>

      <br/>

      <h4>CMPUT 301: Introduction to Software Engineering</h4>
      <p>This class is pretty useful for learning about what your job would be like after graduating. The lecture isn't very applicable to industry,
      but the lab is just building an Android application in a group of 6 people. <br/> Great course for industry, and would highly recommened even if 
      you don't plan to become a software engineer after graduating.
      </p>

      <br/>
      <h4>CMPUT 201: Practical Programming Methodology</h4>
      <p>This class is a prerequisite for many 300 and 400 level courses, so every CS major will take this class. It's a fun class and not that difficult as 
      long as you start the labs early and have the wilingness to learn C. <br/> My prof was Henry Tang and I really enjoyed the class and taking it with him, but
      having other profs may make the class more difficult.
      </p>

      <br/>

      <h4>These are the classes I'm looking forward to: CMPUT 379, CMPUT 333, CMPUT 474 and CMPUT 291. I will write about them when I take them.</h4>

    </div>
  );
};

export default Technologies;
